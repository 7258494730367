import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import classNames from 'classnames';

import s from './Button.module.css';

const Button = ({ className, isLoading, variant, children, ...rest }) => {
    return (
        <button
            className={classNames(s.button, s[variant], className)}
            {...rest}
        >
            {isLoading ? <ClipLoader loading size={30} /> : children}
        </button>
    );
};

Button.defaultProps = {
    variant: 'primary'
};

export default Button;